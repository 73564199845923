import React from "react";
import { Icon } from "semantic-ui-react";

const ragOptions = [];
const ragIsIcon = rag => !ragOptions.includes(rag);

const iconNameMap = {
  white: "question",
  grey: "question",
  red: "exclamation",
  amber: "info",
  green: "check",
  whitetick: "check",
  greytick: "check",
  greystop: "stop",
  greeninverted: "check",
  greysolid: 'circle',
  whitestop: 'circle'
};

const iconColorMap = {
  at: "white",
  users: "white",
  envelope: "white",
  "sort amount up": "white",
  grey: "grey",
  greysolid: 'grey',
  white: "white",
  whitestop: "solidwhite",
  whitetick: "white",
  greytick: "grey",
  greystop: "darkgrey"
};

const iconName = name => iconNameMap[name] || name;
const iconColor = name => iconColorMap[name] || name;

const StatementLight = ({ cog, rag, compliance_perc }) => {
  return (
    <div
      className="statement-light"
      data-rag={

        iconColor(rag || cog || "purple")
      }
    >
      {compliance_perc !== "" && compliance_perc !== undefined ? (
          compliance_perc === 100 ? (<p><small>{compliance_perc}</small></p>) : (<p>{compliance_perc}</p>)
      ) : (
          ragIsIcon(rag || cog) && <Icon fitted name={iconName(rag || cog)} />
      )}
    </div>
  );
};

export default StatementLight;
