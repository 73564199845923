import React from "react";
import { Checkbox, Form, Icon, Label } from "semantic-ui-react";
import API from "../../adapters/API";

class Field extends React.Component {
  render() {
    const { handleFieldChange, field, displayStaticPage } = this.props;

    if (field.type === "checkbox") {
      return (
        <Form.Field>
          <Form.Field>
            <Checkbox
              className="no-margin-bottom"
              label={field.label(() =>
                displayStaticPage(API.staticPageUrls.privacy)
              )}
              onChange={handleFieldChange(field)}
            />
          </Form.Field>
          <Label
            color={field.valid ? "green" : "red"}
            className="signupValidityLabel"
            pointing
          >
            <Icon name={field.valid ? "check" : "x"} />
            {field.validityDescription}
          </Label>
        </Form.Field>
      );
    }

    return (
      <Form.Field key={field.name}>
        <Form.Input
          readOnly={field.readonly}
          label={field.label ? field.label() : field.placeholder}
          type={field.type}
          name={field.name}
          placeholder={field.placeholder}
          onChange={handleFieldChange(field)}
          input={{ autoComplete: field.autocomplete }}
          value={field.value}
        />
        <Label
          color={field.valid ? "green" : "red"}
          className="signupValidityLabel"
          pointing
        >
          <Icon name={field.valid ? "check" : "x"} />
          {field.validityDescription}
        </Label>
      </Form.Field>
    );
  }
}

export default Field;
